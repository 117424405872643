// 人物管理
const all = -1
export const euFrFeature = {
  all: 0, // 全部
  anyEnabled: 1, // 任一啟用(def)
  anyDisabled: 2, // 任一停用
  allDisabled: 3, // 全停用 = 無啟用
  anyTask: 4 // 未註冊 => featureTask.length > 0
}

export const euFrEnabled = {
  all,
  enable: 1, // 啟用(def)
  disable: 0 // 停用
}

// Adv Filters
export const frIdCard = {
  key: 'idCard',
  str: 'frInfo_idCard_filter',
  placeholder: 'fr_filter_id_hint',
  value: ''
}
export const frFeatureSt = {
  key: 'feature',
  str: 'frInfo_feature_filter',
  options: Object.keys(euFrFeature).map((option) => {
    return {
      label: option,
      value: euFrFeature[option],
      str: euFrFeature[option] === all ? option : euFrFeature[option]
    }
  }),
  value: euFrFeature.all
}
export const frEnabledSt = {
  key: 'enabled',
  str: 'frInfo_enabled_filter',
  options: Object.keys(euFrEnabled).map((option) => {
    return {
      label: option,
      value: euFrEnabled[option],
      str: euFrEnabled[option] === all ? option : euFrEnabled[option]
    }
  }),
  value: euFrEnabled.enable
}

export const frInfoFilters = [
  // frIdCard,
  // frFeatureSt,
  frEnabledSt
]
