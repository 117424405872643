import { ptzDeviceModelId } from '@/config/account.js'

const initialState = () => ({
  showPtzPanel: false,
  ptzPanelMode: '', // device, ptz
  autoFocus: false,
  cam: '',
  capability: {
    highSensitivity: false,
    step: false
  },
  configured: [],
  flip: false,
  highSensitivity: false,
  icrMode: 0,
  mirror: false,
  preset: 0,
  pt: '',
  step: 1,
  zoomStep: 6,
  focusStep: 6,
  version: '',
  codecs: '',
  framerate: '',
  resolution: '',
  bitrate: '',
  audioBitrate: '',
  protocol: '',
  dataRxBitrate: { average: '', history: '' },
  videoSrcBitrate: { average: '', history: '' },
  videoSrcFramerate: { average: '', history: '' },
  videoInBitrate: { average: '', history: '' },
  videoInFramerate: { average: '', history: '' },
  videoRxBitrate: { average: '', history: '' },
  videoRxFramerate: { average: '', history: '' },
  videoRxSrcCorrelHistory: '',
  videoRxInCorrelHistory: '',
})

const state = initialState()

const mutations = {
  updatePtzData(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state[key] = value
    })
  },
  resetPtzData(state) {
    const defaultState = initialState()
    Object.keys(state).forEach(key => {
      state[key] = defaultState[key]
    })
  },
  updatePtzKeyValue(state, key, value) {
    state[key] = value
  },
  updateShowPtzPanel(state, payload) {
    state.showPtzPanel = payload
  },
  updatePtzPanelMode(state, payload) {
    state.ptzPanelMode = payload
  },
  updateAutoFocus(state, payload) {
    state.autoFocus = payload
  },
  updateMirror(state, payload) {
    state.mirror = payload
  },
  updateFlip(state, payload) {
    state.flip = payload
  },
  updateHighSensitivity(state, payload) {
    state.highSensitivity = payload
  },
  updateIcrMode(state, payload) {
    state.icrMode = payload
  },
  updateStep(state, payload) {
    state.step = payload
  },
  updateZoomStep(state, payload) {
    state.zoomStep = payload
  },
  updateFocusStep(state, payload) {
    state.focusStep = payload
  },
  updateCodecs(state, payload) {
    state.codecs = payload
  },
  updateFramerate(state, payload) {
    state.framerate = payload
  },
  updateResolution(state, payload) {
    state.resolution = payload
  },
  updateBitrate(state, payload) {
    state.bitrate = payload
  },
  updateAudioBitrate(state, payload) {
    state.audioBitrate = payload
  },
  updateConfigured(state, payload) {
    state.configured = payload
  },
  updateDataRxBitrate(state, {average, history}) {
    state.dataRxBitrate.average = average
    state.dataRxBitrate.history = history
  },
  updateVideoSrcBitrate(state, {average, history}) {
    state.videoSrcBitrate.average = average
    state.videoSrcBitrate.history = history
  },
  updateVideoSrcFramerate(state, {average, history}) {
    state.videoSrcFramerate.average = average
    state.videoSrcFramerate.history = history
  },
  updateVideoInBitrate(state, {average, history}) {
    state.videoInBitrate.average = average
    state.videoInBitrate.history = history
  },
  updateVideoInFramerate(state, {average, history}) {
    state.videoInFramerate.average = average
    state.videoInFramerate.history = history
  },
  updateVideoRxBitrate(state, {average, history}) {
    state.videoRxBitrate.average = average
    state.videoRxBitrate.history = history
  },
  updateVideoRxFramerate(state, {average, history}) {
    state.videoRxFramerate.average = average
    state.videoRxFramerate.history = history
  },
  updateVideoRxSrcCorrelHistory(state, payload) {
    state.videoRxSrcCorrelHistory = payload
  },
  updateVideoRxInCorrelHistory(state, payload) {
    state.videoRxInCorrelHistory = payload
  },
}

const actions = {}

const getters = {
  isSupportPtz(state, getters, rootState) {
    if (!rootState.singleUrlUserID) return false
    // 名稱為R3開頭或內有PTZ的即為PTZ設備
    const live = rootState.liveList?.find((item) => item.id == rootState.singleUrlUserID)
    // 用設備類型(ptzDeviceModelId = [10, 20, 100, 110])判斷
    const user = rootState.userList.find((item) => item.id == rootState.singleUrlUserID)
    const isPtzDevice = ptzDeviceModelId.includes(user.deviceModelId)
    const type = live ? live.device.info.type : ''
    const regex = /^R3/
    return regex.test(type) || type.includes('PTZ') || isPtzDevice
  },
  ptzDeviceId(state, getters, rootState) {
    const live = rootState.liveList?.find((item) => item.id == rootState.singleUrlUserID)
    return live?.device.id
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}