import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from '@/store/index.js'
import 'element-ui/lib/theme-chalk/index.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import PortalVue from 'portal-vue'
import './assets/fonts/font.css'
import i18n from './i18n/i18n.js'
import clearTabIndex from '@/utils/clearTabIndex.js'
import { getMapUrl } from '@/config/map.js'
import VueLazyload from 'vue-lazyload'
import Fragment from 'vue-fragment'
import  {
  Tooltip,
  Tree,
  Row,
  Button,
  Select,
  Option,
  Checkbox,
  Radio,
  Loading,
  Notification
} from 'element-ui'


Vue.use(PortalVue)
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(Fragment.Plugin)
Vue.prototype.$bus = new Vue();

Vue.use(Tooltip)
Vue.use(Tree)
Vue.use(Row)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$notify = Notification

Vue.use(VueGoogleMaps, {
  load: {
    key: getMapUrl(),
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

Tooltip.props.openDelay.default = 300 // Element ui tooltip 延遲0.3秒顯示
Tooltip.props.transition = ''

clearTabIndex.install(Vue)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/icons/no-image.svg'),
  attempt: 1,
  observer: true,
  lazyComponent: true,
})


//const app = 
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')