// 強制 2FA
export const euForced2fa = {
  on: 1, // 強制
  off: 0, // 按需 default
}
export const forced2faOptions = Object.keys(euForced2fa).map((key) => {
  const value = euForced2fa[key]
  return { label: `account_forced2fa_${value}`, value }
})

// 客戶
export const sysCustomize = {
  bovia: `Bovia`,
  npa: 'NPA'
}
export const getCustomizeValue = (str) => {
  const key = str.toLowerCase()
  return key
}

// 客製功能
export const euCustomizeFunction = {
  park: 0,
}
export const customizeFuncKeys = Object.keys(euCustomizeFunction)

// 影像顯示模式：1x1, 2x2, 3x3, 4x4, 1+5, 1+12, 2+8
export const euVideoDisplayMode = {
  mode_1x1: 1,
  mode_2x2: 2,
  mode_3x3: 3,
  mode_4x4: 4,
  mode_1_5: 5, // 1大5小
  mode_1_12: 6, // 1大12小
  mode_2_8: 7, // 2大8小
}
